import React from "react";
import Button from "../../atoms/Button/Button";
import Text from "../../atoms/Text/Text";
import {useI18next} from "gatsby-plugin-react-i18next";
import InternalPathService from "../../../services/InternalPathService";

type propsType = {
    buttonLabel: string,
}

const SwissRXLogin = (props: propsType) => {
    const {language} = useI18next();

    let backUrl = '';
    let currentLanguage = '';
    if (InternalPathService.isBrowserMode()) {
        currentLanguage = language.toUpperCase();
        const orderPageSlug = language === "fr" ? "commander" : "bestellen";
        const returnTo = encodeURIComponent(window.location.origin);
        backUrl = window.location.origin + '/swiss-rx-return.php?returnto=' + returnTo + '%2F' + orderPageSlug + '%2F';
    }

    const TARGET_URL = "https://swiss-rx-login.ch/";
    const GLN = "7601001325050";

    return (
        <div className="swiss-rx-login">
            <form name="formPost" action={TARGET_URL} method="post">
                <input id="GLN" type="hidden" name="GLN" value={GLN} />
                <input id="BackURL" type="hidden" name="BackURL" value={backUrl} />
                <input id="ShowText" type="hidden" name="ShowText" value="MorbusGaucher" />
                <input id="Lang" type="hidden" name="Lang" value={currentLanguage} />
                <Button onSubmit={() => document.forms[0].submit()} uppercase={false} inverted={true} large={true}>
                    <Text text={props.buttonLabel} />
                </Button>
            </form>
        </div>
    );
};

export default SwissRXLogin;