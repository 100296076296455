import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import Text from "../components/atoms/Text/Text";
import DocCheckLogin from "../components/molecules/DocCheckLogin/DocCheckLogin";
import SwissRXLogin from "../components/molecules/SwissRXLogin/SwissRXLogin";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";

type propType = {
    data: any
    classes: any,
    context: any
}

const LoginPage = ({data}: propType) => {
    const {t} = useTranslation();

    return (
        <>
            <FullWidthContainer>
                <StageTeaser image={data.stageTeaser} imageAlt={t('login:imageAlt')}/>

                <h1>{t('login:mainHeadline')}</h1>
                <p>
                    <Text text={t('login:mainCopy')}/>
                </p>
            </FullWidthContainer>
            <FullWidthContainer className="margin-top-2">
                <DocCheckLogin />
            </FullWidthContainer>
            <FullWidthContainer className="margin-top-large" centerContent={true}>
                <SwissRXLogin buttonLabel={t('login:labelSwissRxButton')} />
            </FullWidthContainer>
            <FullWidthContainer className="margin-top-large">
                <PageReferencesText>
                    <Text text="approvalCode" />
                </PageReferencesText>
            </FullWidthContainer>
        </>
    );
}

export default LoginPage;

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		stageTeaser: file(
			relativePath: { eq: "order/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"

				)
			}
		},
	}
`;