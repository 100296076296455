import React from "react";

import * as styles from "./PageReferencesText.module.scss";

const PageReferencesText = ({children}: {children: React.ReactNode}) => (
  <div className={styles.root}>
    {children}
  </div>
);

export default PageReferencesText;