import React from "react";
import {useI18next} from 'gatsby-plugin-react-i18next';

import * as styles from "./DocCheckLogin.module.scss";
import InternalPathService from "../../../services/InternalPathService";

// type propsType = {
//     currentLanguage: string
// }

const DocCheckLogin = () => {
    const {language} = useI18next();
    let encodedReturnUrl = '';
    if (InternalPathService.isBrowserMode()) {
        const orderPageSlug = language === 'fr' ? '/commander' : '/bestellen';
        let origin = window.location.origin;
        encodedReturnUrl = window.btoa(origin + orderPageSlug);
    }

    let loginId = '';
    const languageSetting = language === 'fr' ? 'chfr' : 'chde';
    if (InternalPathService.isStagingEnvironment()) {
        loginId = language === 'fr' ? '2000000018559' : '2000000018556';
    } else if (InternalPathService.isProductionEnvironment()) {
        loginId = language === 'fr' ? '2000000018561' : '2000000018560';
    } else {
        // for now localhost currently the staging logins will be used
        loginId = language === 'fr' ? '2000000018559' : '2000000018556';
    }

    const srcValue = `https://login.doccheck.com/code/${languageSetting}/${loginId}/login_xl/returnto=${encodedReturnUrl}`;

    return (
        <div className={styles.root}>
            <iframe
                className={styles.loginFrame}
                id="dc_login_iframe"
                scrolling="no"
                name="dc_login_iframe"
                width="467"
                height="231"
                src={srcValue}
            />
        </div>
    )
};

export default DocCheckLogin;